import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

// const toggleClass = () => {
//   const oldClassName = document.getElementsByTagName('body')[0].className;
//   const newClassName = oldClassName === 'light' ? 'dark' : 'light'
//   document.getElementsByTagName('body')[0].className = newClassName
// };

export default () => (

  <ThemeToggler>
    {({ theme, toggleTheme }) => (
         <label className="dayNight">
          <input
            type="checkbox"
            className="theme-changer"
            onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')
          }
            checked={theme === 'dark'}
          />{' '}
          <div className="mode-container"></div>
      </label>
      )}
  </ThemeToggler>




  // <ThemeToggler>
  //   {({ theme, toggleTheme }) => (
      
      // <label>
      //   <input
      //     type="checkbox"
      //     className="theme-changer"
      //     onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')
      //   }
      //     checked={theme === 'dark'}
      //   />{' '}
      //   <div className="mode-container">
      //     <i className="gg-sun"></i>
      //     <i className="gg-moon"></i>
      //   </div>
      // </label>


/* <div className="mode-container"> */
// {/* <label className="dayNight">
//     <input type="checkbox" />
//     <div></div>
// </label> */}
/* </div>   */



// <div className="mode-container">
//           <i className="gg-sun"></i>
//           <i className="gg-moon"></i>
//         </div>  
      
  //   )}

//   <div className="mode-container"> 
// <label className="dayNight">
//     <input type="checkbox" onChange={toggleClass} />
//    <div></div>
// </label> 
// </div>   
  // </ThemeToggler>
)