import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/navigation"
import 'prismjs/themes/prism-okaidia.css';
import Wave  from '../components/wave'
import Headroom from "react-headroom"

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div className="site-wraper">
     

      
        <Headroom>
        <div className="site-header">
          {/* <div className="nav-wrapper"> */}
            <div className="site-title">
              <Link to="/">{data.site.siteMetadata.title}</Link>
              </div>
            <Navigation />
            </div>
          {/* </div> */}
      </Headroom>
      
      {/* <header className="site-header">
        <div className="site-title">
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </div>
        <Navigation />
      </header> */}

      <main className="site-main">
      {children}
      </main>
      


    {/* <div className="wawe-container"> */}
        <footer className="site-footer">
          <div className="wave-wrapper">
            <Wave orientation="bottom" />
          </div>
          <div className="content-wrapper"><p>&copy; 2020 Mareatico &bull; Crafted with <span role="img" aria-label="love">❤️</span></p></div>
        </footer>
    {/* </div> */}
</div>
      
      
  )
}